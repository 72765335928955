import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Button, Col, Drawer, Form, Icon, Input, message, Modal, Row, Select, Spin, Switch, Upload} from "antd";


import {API_ERRO_TYPE_VALIDATION} from "../../config/general";

import {FORM_VALIDATION_MESSAGES} from "../../config/lang";

import {adsFilters} from "../../config/filters";

import {adsService, culturesService, typeMachineImplementService} from "../../redux/services";

const FormItem = Form.Item;

const config = {
    images: {
        images: {
            maxSize: 4,
            maxFiles: 1,
            extensions: ["jpg", "png"],
            type: ["image/jpeg", "image/png"],
        },
        images_footer: {
            maxSize: 4,
            maxFiles: 1,
            extensions: ["jpg", "png"],
            type: ["image/jpeg", "image/png"],
        }
    },
};

class AdsForm extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isSending: false,
            id: 0,
            // Images
            imagePreviewVisible: false,
            imagePreviewImage: "",
            imageList: {
                images: [],
                images_footer: []
            },
            imageListDeleted: {
                images: [],
                images_footer: []
            },
            cultures: [],
            companies: [],
            screens: [],
            lists: [],
            sponsored: false,
        };
    }

    fieldOptions = {
        name: {
            label: "Nome",
            decorator: {
                rules: [
                    {required: true, message: "Campo obrigatório."},
                ],
            },
        },
        sponsored: {
            label: "Banner",
            decorator: {
                rules: [],
            },
        },
        screen: {
            label: "Cultura/Conteúdo",
            decorator: {
                rules: [
                    {required: true, message: "Campo obrigatório."},
                ],
            },
        },
        list: {
            label: "Lista",
            decorator: {
                rules: [
                    {},
                ],
            },
        },
        url: {
            label: "Link externo",
            decorator: {
                rules: [],
            },
        },
        is_active: {
            label: "Ativo",
            decorator: {
                valuePropName: "checked",
                initialValue: true,
            },
        },
    };

    onOpen = (id = 0) => {
        this.setState({
            isLoading: true,
            id: id,
        });
        let item = {};
        if (id > 0) {
            adsService.show({id})
                .then((response) => {
                    item = response.data.data;

                    return culturesService.getAll({})
                })
                .then((response) => {
                    let imagesList = {
                        images: [],
                        images_footer: []
                    };

                    if (item.images.length) {
                        item.images.forEach(image => {
                            imagesList.images.push({
                                uid: image.id,
                                name: image.file.split("/").pop(),
                                status: "done",
                                url: image.file,
                                // Has id, is api image
                                id: image.id,
                            });
                        });
                    }

                    if (item.images_footer.length) {
                        item.images_footer.forEach(image => {
                            imagesList.images_footer.push({
                                uid: image.id,
                                name: image.file.split("/").pop(),
                                status: "done",
                                url: image.file,
                                id: image.id,
                            });
                        });
                    }

                    let options = [...response.data.data, ...adsFilters.contents];
                    let find = options.find((i) => i.name == item.screen);
                    let lists = [];

                    // Verifica se possui id identificando uma cultura
                    if (find && find.id) {
                        lists = adsFilters.cultures

                        // Verifica se possui um type identificando um conteudo
                    } else if (find && find.type) {
                        lists = adsFilters[find.type]
                    }

                    this.setState({
                        isLoading: false,
                        imageList: imagesList,
                        screens: options,
                        lists: lists
                    });

                    // Fill form
                    this.fillForm(item);
                })
                .catch((data) => {
                    console.log(data)
                    this.setState({
                        isLoading: false,
                    });

                    Modal.error({
                        title: "Ocorreu um erro!",
                        content: data.error_message,
                        onOk: () => {
                            // Force close
                            return this.onClose();
                        }
                    });
                });
            return;
        }
        culturesService.getAll({})
            .then((response) => {
                this.setState({
                    screens: [...response.data.data, ...adsFilters.contents],
                    isLoading: false
                });
            })
            .catch((data) => {
                this.setState({
                    isLoading: false
                });
            });
    };

    fillForm = (data) => {
        this.props.form.setFieldsValue({
            name: data.name,
            screen: data.screen,
            list: data.list,
            url: data.url,
            is_active: data.is_active,
            sponsored: data.sponsored,
        });
    };

    resetFields = () => {
        this.props.form.resetFields();

        this.setState({
            imageList: {
                images: [],
                images_footer: []
            },
            imageListDeleted: {
                images: [],
                images_footer: []
            },
            cultures: [],
            companies: [],
        });
    };

    onClose = () => {
        // Reset fields
        this.resetFields();

        // Callback
        this.props.onClose();
    };

    onSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (!error) {
                this.setState({
                    isSending: true,
                });

                const {id, imageList, imageListDeleted} = this.state;

                if (id > 0) {
                    // ID
                    values.id = id;

                    // Images
                    if( imageList.images.length )
                    {
                        let images = imageList.images.filter(image => !image.id);

                        if( images.length )
                        {
                            values.images = images;
                        }
                    }

                    // Images footer
                    if( imageList.images_footer.length )
                    {
                        let images = imageList.images_footer.filter(image => !image.id);

                        if( images.length )
                        {
                            values.images_footer = images;
                        }
                    }

                    // Images delete
                    if( imageListDeleted.images.length )
                    {
                        values.delete_images = imageListDeleted.images;
                    }

                    // Images delete footer
                    if( imageListDeleted.images_footer.length )
                    {
                        values.delete_images = imageListDeleted.images_footer;
                    }

                    adsService.edit(values)
                        .then((response) => {
                            this.setState({
                                isSending: false,
                            });

                            // Reset fields
                            this.resetFields();

                            // Success message
                            message.success("Registro atualizado com sucesso.");

                            // Callback
                            this.props.onComplete(response.data.data);
                        })
                        .catch((data) => {
                            this.setState({
                                isSending: false,
                            });

                            // if validation error
                            if( data.error_type === API_ERRO_TYPE_VALIDATION )
                            {
                                let hasFieldsErrors = false;

                                for( let key in data.error_errors )
                                {
                                    if( data.error_errors[key] && this.fieldOptions[key] )
                                    {
                                        this.props.form.setFields({
                                            [key]: {
                                                value : values[key],
                                                errors: [new Error(data.error_errors[key])],
                                            }
                                        });

                                        hasFieldsErrors = true;
                                    }
                                }

                                if( !hasFieldsErrors )
                                {
                                    Modal.error({
                                        title  : "Ocorreu um erro!",
                                        content: data.error_message,
                                    });
                                }
                            }
                            else
                            {
                                Modal.error({
                                    title  : "Ocorreu um erro!",
                                    content: data.error_message,
                                });
                            }
                        });
                    return;
                }

                // Images
                if (imageList.images.length) {
                    values.images = imageList.images;
                }

                // Images footer
                if (imageList.images_footer.length) {
                    values.images_footer = imageList.images_footer;
                }

                adsService.create(values)
                    .then((response) => {
                        this.setState({
                            isSending: false,
                        });

                        // Reset fields
                        this.resetFields();

                        // Success message
                        message.success("Registro cadastrado com sucesso.");

                        // Callback
                        this.props.onComplete(response.data.data);
                    })
                    .catch((data) => {
                        console.log(data)
                        this.setState({
                            isSending: false,
                        });

                        // if validation error
                        if (data.error_type === API_ERRO_TYPE_VALIDATION) {
                            let hasFieldsErrors = false;

                            for (let key in data.error_errors) {
                                if (data.error_errors[key] && this.fieldOptions[key]) {
                                    this.props.form.setFields({
                                        [key]: {
                                            value: values[key],
                                            errors: [new Error(data.error_errors[key])],
                                        }
                                    });

                                    hasFieldsErrors = true;
                                }
                            }

                            if (!hasFieldsErrors) {
                                Modal.error({
                                    title: "Ocorreu um erro!",
                                    content: data.error_message,
                                });
                            }
                        } else {
                            Modal.error({
                                title: "Ocorreu um erro!",
                                content: data.error_message,
                            });
                        }
                    });
            }
        });
    };

    onSelectScreen = (item) => {
        let find = this.state.screens.find((i) => i.name == item);

        this.props.form.setFieldsValue({
            list: null
        });

        // Verifica se possui id identificando uma cultura
        if (find && find.id) {
            this.setState({
                lists: adsFilters.cultures,
                screen: item
            });

            // Verifica se possui um type identificando um conteudo
        } else if (find && find.type) {
            if (find.type === "collection-machines-implements") {
                typeMachineImplementService.getAll()
                    .then((response) => {
                        this.setState({
                            lists: response.data.data.map(item => item.name),
                            screen: item
                        })
                    })
                this.setState({})
            } else {
                this.setState({
                    lists: adsFilters[find.type],
                    screen: item
                });
            }
        } else {
            this.setState({
                lists: []
            })
        }
    }

    onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

    onImagePreview = (type, file) => {
        this.setState({
            imagePreviewImage: file.url,
            imagePreviewVisible: true,
        });
    };

    onImageRemove = (type, file) => {
        const isEdit = this.state.id > 0;
        let imagesNew = [...this.state.imageList[type]];
        let imageListDeletedNew = [...this.state.imageListDeleted[type]];

        const index = imagesNew.findIndex(item => file.uid === item.uid);

        if (index !== -1) {
            imagesNew.splice(index, 1);

            if(isEdit){
                // Has id
                if( file.id )
                {
                    imageListDeletedNew.push(file.id);
                }
                this.setState(state => ({
                    imageListDeleted: {
                        ...state.imageListDeleted,
                        [type]: imageListDeletedNew,
                    },
                }));
            }

            this.setState(state => ({
                imageList: {
                    ...state.imageList,
                    [type]: imagesNew,
                },
            }));
        }
    };

    renderImages(type) {
        const imageList = this.state.imageList[type];
        const imageConfig = config.images[type];

        const uploadButton = (
            <div>
                <Icon type="plus"/>
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <div className="media-images-wrap">
                <Upload
                    accept={`.${imageConfig.extensions.join(",.")}`}
                    listType="picture-card"
                    className="media-images-uploader"
                    fileList={imageList}
                    onPreview={(file) => this.onImagePreview(type, file)}
                    onRemove={(file) => this.onImageRemove(type, file)}
                    beforeUpload={(file) => {
                        if (!imageConfig.type.includes(file.type)) {
                            message.error(`Somente são aceitos arquivos ${imageConfig.extensions.join(", ").toUpperCase()}!`);

                            return false;
                        }

                        const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

                        if (!isValidSize) {
                            message.error(`A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`);

                            return false;
                        }

                        let reader = new FileReader();
                        reader.onload = (e) => {
                            let imagesNew = [...this.state.imageList[type]];

                            if (imagesNew.length < imageConfig.maxFiles) {
                                // Base64
                                file.url = e.target.result;

                                imagesNew.push(file);

                                this.setState(state => ({
                                    imageList: {
                                        ...state.imageList,
                                        [type]: imagesNew,
                                    },
                                }));
                            }
                        };

                        reader.readAsDataURL(file);

                        return false;
                    }}>
                    {imageList.length >= imageConfig.maxFiles ? null : uploadButton}
                </Upload>
            </div>
        );
    }

    render() {
        const {visible, form} = this.props;
        const {id, isLoading, isSending, imagePreviewVisible, imagePreviewImage, screens, lists} = this.state;

        const {getFieldDecorator} = form;

        return (
            <Drawer
                visible={visible}
                className="drawer-form"
                width={410}
                maskClosable={false}
                closable={false}
                keyboard={false}
                placement="right"
                onClose={this.onClose}>
                <Form layout="vertical" onSubmit={this.onSubmit}>
                    <div className="form-header">
                        <Button className="btn-close" onClick={this.onClose} icon="close"
                                disabled={isLoading || isSending}/>
                        <div className="ant-drawer-title">{id === 0 ? `Inserir novo registro` : `Editar registro [${id}]`}</div>
                        <Button type="primary" htmlType="submit" className="btn-save" icon="check" loading={isSending}
                                disabled={isLoading}>Salvar</Button>
                    </div>
                    {isLoading ? (
                        <div className="text-center" style={{padding: 20}}>
                            <Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin/>}/>
                        </div>
                    ) : (
                        <div className="form-body">

                            <Row gutter={16}>
                                <Col xs={24} sm={12}>
                                    <FormItem label="Banner topo">
                                        {this.renderImages("images")}
                                        <small>Dimensões: (1000x320px)</small>
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <FormItem label="Banner rodapé">
                                        {this.renderImages("images_footer")}
                                        <small>Dimensões: (1000x320px)</small>
                                    </FormItem>
                                </Col>
                            </Row>

                            <FormItem label={this.fieldOptions.name.label} hasFeedback>
                                {getFieldDecorator("name", this.fieldOptions.name.decorator)(
                                    <Input/>
                                )}
                            </FormItem>
                            <FormItem label={this.fieldOptions.screen.label} hasFeedback>
                                {getFieldDecorator("screen", this.fieldOptions.screen.decorator)(
                                    <Select
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        allowClear
                                        onSelect={(item) => this.onSelectScreen(item)}
                                        showSearch>
                                        {screens.map((item, index) => (
                                            <Select.Option key={`screens-${index}`} value={item.name}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </FormItem>
                            <FormItem label={this.fieldOptions.list.label} hasFeedback>
                                {getFieldDecorator("list", this.fieldOptions.list.decorator)(
                                    <Select
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        allowClear
                                        showSearch>
                                        {lists.map((item, index) => (
                                            <Select.Option key={`lists-${index}`} value={item}>{item}</Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </FormItem>
                            <FormItem label={this.fieldOptions.url.label} hasFeedback>
                                {getFieldDecorator("url", this.fieldOptions.url.decorator)(
                                    <Input/>
                                )}
                            </FormItem>
                            <Row gutter={16}>
                                <Col xs={8}>
                                    <FormItem label={this.fieldOptions.is_active.label}>
                                        {getFieldDecorator("is_active", this.fieldOptions.is_active.decorator)(
                                            <Switch/>
                                        )}
                                    </FormItem>
                                </Col>
                                <Col xs={8}>
                                    <FormItem label={this.fieldOptions.sponsored.label}>
                                        {getFieldDecorator("sponsored", this.fieldOptions.sponsored.decorator)(
                                            <Switch/>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Form>
                <Modal className="modal-image" visible={imagePreviewVisible} footer={null} destroyOnClose={true}
                       onCancel={this.onImagePreviewClose}>
                    <img src={imagePreviewImage}/>
                </Modal>
            </Drawer>
        )
    }
}

export default Form.create({
    validateMessages: FORM_VALIDATION_MESSAGES,
})(AdsForm);
