import {
    Button, Col,
    Drawer,
    Form,
    Icon,
    Input,
    message,
    Modal, Row, Select,
    Switch,
    Upload,
} from "antd";
import * as PropTypes from "prop-types";
import React, {Component} from "react";
import "react-quill/dist/quill.snow.css";

import {API_ERRO_TYPE_VALIDATION} from "../../config/general";

import {FORM_VALIDATION_MESSAGES} from "../../config/lang";

import {mediaNewsService} from "./../../redux/services";
import TextEditor from "../../components/TextEditor";

const FormItem = Form.Item;

const config = {
    images: {
        file: {
            maxSize: 4,
            maxFiles: 1,
            extensions: ["jpg", "png"],
            type: ["image/jpeg", "image/png"],
        },
        ads: {
            maxSize: 4,
            maxFiles: 1,
            extensions: ["jpg", "png"],
            type: ["image/jpeg", "image/png"],
        },
    },
};

class Create extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            text: "",
            title: "",
            link: "",
            type: 1,
            content_type: 1,
            // Images
            imagePreviewVisible: false,
            imagePreviewImage: "",
            imageList: {
                file: [],
            },
        };
    }

    fieldOptions = {
        title: {
            label: "Título",
            decorator: {
                rules: [{required: true, message: "Campo obrigatório."}],
            },
        },
        text: {
            label: "Texto",
            decorator: {
                rules: [{required: true, message: "Campo obrigatório."}],
            },
        },
        link: {
            label: "Link",
            decorator: {
                rules: [{required: true, message: "Campo obrigatório."}],
            },
        },
        type: {
            label: "Tipo",
            decorator: {
                initialValue: 1,
                rules: [{required: true, message: "Campo obrigatório."}],
            },
        },
        content_type: {
            label: "Tipo de Conteúdo",
            decorator: {
                initialValue: 1,
                rules: [{required: true, message: "Campo obrigatório."}],
            },
        },
        is_active: {
            label: "Ativo",
            decorator: {
                valuePropName: "checked",
                initialValue: true,
            },
        },
    };

    onOpen = () => {
    };


    resetFields = () => {
        this.props.form.resetFields();

        this.setState({
            imageList: {
                file: [],
            },
            imageAdsList: {
                images: [],
            },
        });
    };

    onClose = () => {
        // Reset fields
        this.resetFields();

        // Callback
        this.props.onClose();
    };

    onSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (!error) {
                const {imageList, imageAdsList} = this.state;

                this.setState({
                    isSending: true,
                });

                // File
                values.file = imageList.file.length ? imageList.file[0] : null;

                mediaNewsService
                    .create(values)
                    .then(() => {
                        this.setState({
                            isSending: false,
                        });

                        // Reset fields
                        this.resetFields();

                        // Success message
                        message.success("Registro cadastrado com sucesso.");

                        // Callback
                        this.props.onComplete();
                    })
                    .catch((data) => {
                        this.setState({
                            isSending: false,
                        });

                        // if validation error
                        if (data.error_type === API_ERRO_TYPE_VALIDATION) {
                            let hasFieldsErrors = false;

                            for (let key in data.error_errors) {
                                if (
                                    data.error_errors[key] &&
                                    this.fieldOptions[key]
                                ) {
                                    this.props.form.setFields({
                                        [key]: {
                                            value: values[key],
                                            errors: [new Error(data.error_errors[key])],
                                        },
                                    });

                                    hasFieldsErrors = true;
                                }
                            }

                            if (!hasFieldsErrors) {
                                Modal.error({
                                    title: "Ocorreu um erro!",
                                    content: data.error_message,
                                });
                            }
                        } else {
                            Modal.error({
                                title: "Ocorreu um erro!",
                                content: data.error_message,
                            });
                        }
                    });
            }
        });
    };

    onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

    onImagePreview = (type, file) => {
        this.setState({
            imagePreviewImage: file.url,
            imagePreviewVisible: true,
        });
    };

    onImageRemove = (type, file) => {
        let imagesNew = [...this.state.imageList[type]];

        const index = imagesNew.findIndex((item) => file.uid === item.uid);

        if (index !== -1) {
            imagesNew.splice(index, 1);

            this.setState((state) => ({
                imageList: {
                    ...state.imageList,
                    [type]: imagesNew,
                },
            }));
        }
    };

    onImageAdsPreviewClose = () =>
        this.setState({imageAdsPreviewVisible: false});

    onImageAdsPreview = (type, file) => {
        this.setState({
            imageAdsPreviewImage: file.url,
            imageAdsPreviewVisible: true,
        });
    };

    onImageAdsRemove = (type, file) => {
        let imagesAdsNew = [...this.state.imageAdsList[type]];
        let imageAdsListDeletedNew = [...this.state.imageAdsListDeleted[type]];

        const index = imagesAdsNew.findIndex((item) => file.uid === item.uid);

        if (index !== -1) {
            imagesAdsNew.splice(index, 1);

            // Has id
            if (file.id) {
                imageAdsListDeletedNew.push(file.id);
            }

            this.setState((state) => ({
                imageAdsList: {
                    ...state.imageAdsList,
                    [type]: imagesAdsNew,
                },
                imageAdsListDeleted: {
                    ...state.imageAdsListDeleted,
                    [type]: imageAdsListDeletedNew,
                },
            }));
        }
    };

    renderImages(type) {
        const imageList = this.state.imageList[type];
        const imageConfig = config.images[type];

        const uploadButton = (
            <div>
                <Icon type="plus"/>
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <div className="media-images-wrap">
                <Upload
                    accept={`.${imageConfig.extensions.join(",.")}`}
                    listType="picture-card"
                    className="media-images-uploader"
                    fileList={imageList}
                    onPreview={(file) => this.onImagePreview(type, file)}
                    onRemove={(file) => this.onImageRemove(type, file)}
                    beforeUpload={(file) => {
                        if (!imageConfig.type.includes(file.type)) {
                            message.error(
                                `Somente são aceitos arquivos ${imageConfig.extensions
                                    .join(", ")
                                    .toUpperCase()}!`
                            );

                            return false;
                        }

                        const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

                        if (!isValidSize) {
                            message.error(
                                `A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`
                            );

                            return false;
                        }

                        let reader = new FileReader();
                        reader.onload = (e) => {
                            let imagesNew = [...this.state.imageList[type]];

                            if (imagesNew.length < imageConfig.maxFiles) {
                                // Base64
                                file.url = e.target.result;

                                imagesNew.push(file);

                                this.setState((state) => ({
                                    imageList: {
                                        ...state.imageList,
                                        [type]: imagesNew,
                                    },
                                }));
                            }
                        };

                        reader.readAsDataURL(file);

                        return false;
                    }}
                >
                    {imageList.length >= imageConfig.maxFiles ? null : uploadButton}
                </Upload>
            </div>
        );
    }

    render() {
        const {visible, form} = this.props;
        const {
            isSending,
            imagePreviewVisible,
            imagePreviewImage,
        } = this.state;

        const {getFieldDecorator} = form;

        return (
            <Drawer
                visible={visible}
                className="drawer-form"
                width={"50%"}
                maskClosable={false}
                closable={false}
                keyboard={true}
                placement="right"
                onClose={this.onClose}
            >
                <Form layout="vertical" onSubmit={this.onSubmit}>
                    <div className="form-header">
                        <Button
                            className="btn-close"
                            onClick={this.onClose}
                            icon="close"
                            disabled={isSending}
                        />
                        <div className="ant-drawer-title">Inserir novo registro</div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btn-save"
                            icon="check"
                            loading={isSending}
                        >
                            Salvar
                        </Button>
                    </div>
                    <div className="form-body">
                        <FormItem label={this.fieldOptions.is_active.label}>
                            {getFieldDecorator(
                                "is_active",
                                this.fieldOptions.is_active.decorator
                            )(<Switch/>)}
                        </FormItem>
                        <Row gutter={16}>
                            <Col xs={12} sm={6} lg={4}>
                                <FormItem label="Imagem">{this.renderImages("file")}</FormItem>
                            </Col>
                            <Col xs={12} sm={10} lg={12}>
                                <FormItem label={this.fieldOptions.type.label} hasFeedback>
                                    {getFieldDecorator("type", this.fieldOptions.type.decorator)(
                                        <Select>
                                            <Select.Option key="mediaNewsType1" value={1} >Youtube</Select.Option>
                                            <Select.Option key="mediaNewsType2" value={2}>Spotify</Select.Option>
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <FormItem label={this.fieldOptions.title.label} hasFeedback>
                            {getFieldDecorator(
                                "title",
                                this.fieldOptions.title.decorator
                            )(<Input/>)}
                        </FormItem>
                        <FormItem label={this.fieldOptions.link.label} hasFeedback>
                            {getFieldDecorator(
                                "link",
                                this.fieldOptions.link.decorator
                            )(<Input autoComplete="off"/>)}
                        </FormItem>
                        <FormItem label={this.fieldOptions.content_type.label} hasFeedback>
                            {getFieldDecorator("content_type", this.fieldOptions.content_type.decorator)(
                                <Select>
                                    {this.props.contentTypes.length > 0 && this.props.contentTypes.map(item => (
                                    <Select.Option key={item.id} value={item.id} >{item.name}</Select.Option>
                                    ))}
                                </Select>
                            )}
                        </FormItem>
                        <FormItem label={this.fieldOptions.text.label} hasFeedback>
                            {getFieldDecorator(
                                "text",
                                this.fieldOptions.text.decorator
                            )(
                                <TextEditor/>
                            )}
                        </FormItem>
                    </div>
                </Form>
                <Modal
                    className="modal-image"
                    visible={imagePreviewVisible}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={this.onImagePreviewClose}
                >
                    <img src={imagePreviewImage}/>
                </Modal>
            </Drawer>
        );
    }
}

export default Form.create({
    validateMessages: FORM_VALIDATION_MESSAGES,
})(Create);
