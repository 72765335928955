import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Button, Col, Drawer, Form, Icon, Input, message, Modal, Row, Spin, Switch, Upload} from "antd";

import {API_ERRO_TYPE_VALIDATION} from "./../../config/general";

import {FORM_VALIDATION_MESSAGES} from "./../../config/lang";

import {newsService} from "./../../redux/services";
import TextEditor from "../../components/TextEditor";

const FormItem = Form.Item;

const config = {
	images: {
		file: {
			maxSize   : 4,
			maxFiles  : 1,
			extensions: ["jpg", "png"],
			type      : ["image/jpeg", "image/png"],
		},
		ads: {
			maxSize   : 4,
			maxFiles  : 1,
			extensions: ["jpg", "png"],
			type      : ["image/jpeg", "image/png"],
		}
	},
};

class Edit extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			text 			   : "",
			isLoading          : true,
			isSending          : false,
			id                 : 0,
			// Images
			imagePreviewVisible: false,
			imagePreviewImage  : "",
			imageList          : {
				file: [],
			},
			imageListDeleted   : {
				file: [],
			},
			imageAdsList          : {
				images: [],
			},
			imageAdsListDeleted   : {
				images: [],
			},
		};
	}

	fieldOptions = {
		name     : {
			label    : "Nome",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		author   : {
			label    : "Autor",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		text     : {
			label    : "Texto",
			decorator: {},
		},
		ads_url		   : {
			label    : "Link externo",
			decorator: {
				rules: [],
			},
		},
		pdf_url		   : {
			label    : "Link PDF",
			decorator: {
				rules: [],
			},
		},
		is_active: {
			label    : "Ativo",
			decorator: {
				valuePropName: "checked",
			},
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
		});

		newsService.show({id})
		.then((response) => {
			let item = response.data.data;
			this.setState({
				isLoading: false,
			});

			let imagesAdsList = {
				images: [],
			};

			if( item.ads )
			{
				imagesAdsList.images.push({
					uid   : item.id,
					name  : item.ads.split("/").pop(),
					status: "done",
					url   : item.ads,
					// Has id, is api image
					id    : item.id,
				});
			}

			this.setState({
				imageAdsList: imagesAdsList,
			});

			// Fill form
			this.fillForm(item);
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	fillForm = (data) => {
		this.props.form.setFieldsValue({
			name     : data.name,
			author   : data.author,
			text     : data.text,
			ads_url	 : data.ads_url,
			pdf_url: data.pdf_url,
			is_active: data.is_active,
		});

		let imagesList = {
			file: [],
		};

		if( data.file )
		{
			imagesList.file.push({
				uid   : 1,
				name  : data.file.split("/").pop(),
				status: "done",
				url   : data.file,
				// Has id, is api image
				id    : 1,
			});
		}

		this.setState({
			imageList: imagesList,
		});
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			imageList       : {
				file: [],
			},
			imageAdsList: {
				images: [],
			},
			imageListDeleted: {
				file: [],
			},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				this.setState({
					isSending: true,
				});

				const {id, imageList, imageAdsList, imageListDeleted, imageAdsListDeleted} = this.state;

				// ID
				values.id = id;

				// File
				if( imageList.file.length )
				{
					if( !imageList.file[0].id )
					{
						values.file = imageList.file[0];
					}
				}
				else
				{
					values.file = null;
				}

				// Images ads
				if( imageAdsList.images.length )
				{
					let images = imageAdsList.images.filter(image => !image.id);

					if( images.length )
					{
						values.ads = images;
					}
				}

				newsService.edit(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Registro atualizado com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

	onImagePreview = (type, file) => {
		this.setState({
			imagePreviewImage  : file.url,
			imagePreviewVisible: true,
		});
	};

	onImageRemove = (type, file) => {
		let imagesNew           = [...this.state.imageList[type]];
		let imageListDeletedNew = [...this.state.imageListDeleted[type]];

		const index = imagesNew.findIndex(item => file.uid === item.uid);

		if( index !== -1 )
		{
			imagesNew.splice(index, 1);

			// Has id
			if( file.id )
			{
				imageListDeletedNew.push(file.id);
			}

			this.setState(state => ({
				imageList       : {
					...state.imageList,
					[type]: imagesNew,
				},
				imageListDeleted: {
					...state.imageListDeleted,
					[type]: imageListDeletedNew,
				},
			}));
		}
	};

	onImageAdsPreviewClose = () => this.setState({imageAdsPreviewVisible: false});

	onImageAdsPreview = (type, file) => {
		this.setState({
			imageAdsPreviewImage  : file.url,
			imageAdsPreviewVisible: true,
		});
	};

	onImageAdsRemove = (type, file) => {
		let imagesAdsNew           = [...this.state.imageAdsList[type]];
		let imageAdsListDeletedNew = [...this.state.imageAdsListDeleted[type]];

		const index = imagesAdsNew.findIndex(item => file.uid === item.uid);

		if( index !== -1 )
		{
			imagesAdsNew.splice(index, 1);

			// Has id
			if( file.id )
			{
				imageAdsListDeletedNew.push(file.id);
			}

			this.setState(state => ({
				imageAdsList       : {
					...state.imageAdsList,
					[type]: imagesAdsNew,
				},
				imageAdsListDeleted: {
					...state.imageAdsListDeleted,
					[type]: imageAdsListDeletedNew,
				},
			}));
		}
	};

	renderAds(type) {
		const imageAdsList = this.state.imageAdsList[type];
		const imageConfig  = config.images["ads"];

		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<div className="media-images-wrap">
				<Upload
					accept={`.${imageConfig.extensions.join(",.")}`}
					listType="picture-card"
					className="media-images-uploader"
					fileList={imageAdsList}
					onPreview={(file) => this.onImageAdsPreview(type, file)}
					onRemove={(file) => this.onImageAdsRemove(type, file)}
					beforeUpload={(file) => {
						if( !imageConfig.type.includes(file.type) )
						{
							message.error(`Somente são aceitos arquivos ${imageConfig.extensions.join(", ").toUpperCase()}!`);

							return false;
						}

						const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

						if( !isValidSize )
						{
							message.error(`A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`);

							return false;
						}

						let reader    = new FileReader();
						reader.onload = (e) => {
							let imagesAdsNew = [...this.state.imageAdsList[type]];

							if( imagesAdsNew.length < imageConfig.maxFiles )
							{
								// Base64
								file.url = e.target.result;

								imagesAdsNew.push(file);

								this.setState(state => ({
									imageAdsList: {
										...state.imageAdsList,
										[type]: imagesAdsNew,
									},
								}));
							}
						};

						reader.readAsDataURL(file);

						return false;
					}}>
					{imageAdsList.length >= imageConfig.maxFiles ? null : uploadButton}
				</Upload>
			</div>
		);
	}

	renderImages(type) {
		const imageList   = this.state.imageList[type];
		const imageConfig = config.images[type];

		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<div className="media-images-wrap">
				<Upload
					accept={`.${imageConfig.extensions.join(",.")}`}
					listType="picture-card"
					className="media-images-uploader"
					fileList={imageList}
					onPreview={(file) => this.onImagePreview(type, file)}
					onRemove={(file) => this.onImageRemove(type, file)}
					beforeUpload={(file) => {
						if( !imageConfig.type.includes(file.type) )
						{
							message.error(`Somente são aceitos arquivos ${imageConfig.extensions.join(", ").toUpperCase()}!`);

							return false;
						}

						const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

						if( !isValidSize )
						{
							message.error(`A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`);

							return false;
						}

						let reader    = new FileReader();
						reader.onload = (e) => {
							let imagesNew = [...this.state.imageList[type]];

							if( imagesNew.length < imageConfig.maxFiles )
							{
								// Base64
								file.url = e.target.result;

								imagesNew.push(file);

								this.setState(state => ({
									imageList: {
										...state.imageList,
										[type]: imagesNew,
									},
								}));
							}
						};

						reader.readAsDataURL(file);

						return false;
					}}>
					{imageList.length >= imageConfig.maxFiles ? null : uploadButton}
				</Upload>
			</div>
		);
	}

	render() {
		const {visible, form}                                                    = this.props;
		const {id, isLoading, isSending, imagePreviewVisible, imagePreviewImage, imageAdsPreviewVisible, imageAdsPreviewImage} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={500}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical" onSubmit={this.onSubmit}>
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading || isSending} />
						<div className="ant-drawer-title">{`Editar registro [${id}]`}</div>
						<Button type="primary" htmlType="submit" className="btn-save" icon="check" loading={isSending} disabled={isLoading}>Salvar</Button>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label="Imagem">
								{this.renderImages("file")}
							</FormItem>
							<Row gutter={16}>
								<Col xs={24} md={12}>
									<FormItem label="Ads">
										{this.renderAds("images")}
								<small>Dimensões: (940x780px)</small>
									</FormItem>
								</Col>
								<Col xs={24} md={12}>
									<FormItem label={this.fieldOptions.ads_url.label} hasFeedback>
										{getFieldDecorator("ads_url", this.fieldOptions.ads_url.decorator)(
											<Input />
										)}
									</FormItem>
								</Col>
							</Row>
							<FormItem label={this.fieldOptions.name.label} hasFeedback>
								{getFieldDecorator("name", this.fieldOptions.name.decorator)(
									<Input />
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.author.label} hasFeedback>
								{getFieldDecorator("author", this.fieldOptions.author.decorator)(
									<Input />
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.text.label} hasFeedback>
								{getFieldDecorator("text", this.fieldOptions.text.decorator)(
									<TextEditor/>
								)}
							</FormItem>
							<FormItem label={this.fieldOptions.pdf_url.label} hasFeedback>
							{getFieldDecorator("pdf_url", this.fieldOptions.pdf_url.decorator)(
								<Input />
							)}
						</FormItem>
							<FormItem label={this.fieldOptions.is_active.label}>
								{getFieldDecorator("is_active", this.fieldOptions.is_active.decorator)(
									<Switch />
								)}
							</FormItem>
						</div>
					)}
				</Form>
				<Modal className="modal-image" visible={imagePreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImagePreviewClose}>
					<img src={imagePreviewImage} />
				</Modal>
				<Modal className="modal-image" visible={imageAdsPreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImageAdsPreviewClose}>
					<img src={imageAdsPreviewImage} />
				</Modal>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Edit);
