import React, {Component} from "react";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {NavLink, withRouter} from "react-router-dom";
import {Menu, Icon} from "antd";
import {sortByText} from "../helpers/utils"

const SubMenu = Menu.SubMenu;

class MainNavigation extends Component {
    static propTypes = {
        onClick: PropTypes.func,
    };

    static defaultProps = {
        onClick: () => null,
    };

    render() {
        const {location} = this.props;
        let base = "";
        let defaultOpenKeys = [];
        let paths = location.pathname.split("/").filter(function (el) {
            return el;
        });

        paths.forEach((path, index) => {
            if (path) {
                defaultOpenKeys.push(`/${path}`);

                if (index === 0) {
                    base = `/${path}`;
                }
            }
        });

        const conteudoSubMenu = [
            {
                permission: 'contracts.list',
                url: '/contents/contracts',
                text: 'Contratos',
            },
            {
                permission: 'credit.list',
                url: '/contents/credit',
                text: 'Crédito',
            },
            {
                permission: 'cultures.list',
                url: '/contents/cultures',
                text: 'Culturas',
            },
            {
                permission: 'defensives.list',
                url: '/contents/defensives',
                text: 'Defensivos',
            },
            {
                permission: 'nutritional-deficiencies.list',
                url: '/contents/nutritional-deficiencies',
                text: 'Deficiências nutricionais',
            },
            {
                permission: 'diseases.list',
                url: '/contents/diseases',
                text: 'Doenças',
            },
            {
                permission: 'companies.list',
                url: '/contents/companies',
                text: 'Empresas',
            },
            {
                permission: 'fertilizers.list',
                url: '/contents/fertilizers',
                text: 'Fertilizantes/Corretivos',
            },
            {
                permission: 'leaf-fertilizers.list',
                url: '/contents/leaf-fertilizers',
                text: 'Fertilizantes foliares',
            },
            {
                permission: 'chemical-groups.list',
                url: '/contents/chemical-groups',
                text: 'Grupos químicos',
            },
            {
                permission: 'active-ingredients.list',
                url: '/contents/active-ingredients',
                text: 'Ingredientes ativos',
            },
            {
                permission: 'machines-implements.list',
                url: '/contents/types-machines-implements',
                text: 'Tipos de Máquinas/Implementos',
            },
            {
                permission: 'machines-implements.list',
                url: '/contents/machines-implements',
                text: 'Máquinas/Implementos',
            },
            {
                permission: 'machines-implements.list',
                url: '/contents/type-animals',
                text: 'Tipos de Animais',
            },
            {
                permission: 'machines-implements.list',
                url: '/contents/animals',
                text: 'Animais',
            },
            {
                permission: 'news.list',
                url: '/contents/news',
                text: 'Notícias',
            },
            {
                permission: 'news.list',
                url: '/contents/sponsoredpost',
                text: 'Publicação patrocinada',
            },
            {
                permission: 'weeds.list',
                url: '/contents/weeds',
                text: 'Plantas daninhas',
            },
            {
                permission: 'pests.list',
                url: '/contents/pests',
                text: 'Pragas',
            },
            {
                permission: 'tables-conversions.list',
                url: '/contents/tables-conversions',
                text: 'Tabelas/Conversões',
            },
            {
                permission: 'tutorials-categories.list',
                url: '/contents/tutorials-categories',
                text: 'Tutoriais - Categorias',
            },
            {
                permission: 'tutorials-categories.list',
                url: '/contents/tutorials-authors',
                text: 'Tutoriais - Autores',
            },
            {
                permission: 'tutorials.list',
                url: '/contents/tutorials',
                text: 'Tutoriais',
            },
            {
                permission: 'varieties.list',
                url: '/contents/varieties',
                text: 'Variedades',
            },
            {
                permission: 'news.list',
                url: '/contents/media',
                text: 'Mídia',
            }
        ];
        return (
            <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname, base]}
                  defaultOpenKeys={defaultOpenKeys} selectedKeys={[location.pathname, base]}
                  onClick={this.props.onClick}>
                <Menu.Item key="/">
                    <NavLink to="/">
                        <Icon type="dashboard"/>
                        <span className="nav-text">Dashboard</span>
                    </NavLink>
                </Menu.Item>
                {(
                    this.props.permissions.includes("users.list")
                    || this.props.permissions.includes("roles.list")
                    || this.props.permissions.includes("log.list")
                    || this.props.permissions.includes("system-log.list")) && (
                    <SubMenu key="/administrator" title={<span><Icon type="control"/><span>Administrador</span></span>}>
                        {this.props.permissions.includes("users.list") && <Menu.Item key="/administrator/users">
                            <NavLink to="/administrator/users">
                                <span className="nav-text">Usuários</span>
                            </NavLink>
                        </Menu.Item>}
                        {this.props.permissions.includes("roles.list") &&
                            <Menu.Item key="/administrator/roles-and-permissions">
                                <NavLink to="/administrator/roles-and-permissions">
                                    <span className="nav-text">Papéis e permissões</span>
                                </NavLink>
                            </Menu.Item>}
                        {this.props.permissions.includes("log.list") && <Menu.Item key="/administrator/logs">
                            <NavLink to="/administrator/logs">
                                <span className="nav-text">Registro de alterações</span>
                            </NavLink>
                        </Menu.Item>}
                        {this.props.permissions.includes("system-log.list") &&
                            <Menu.Item key="/administrator/system-log">
                                <NavLink to="/administrator/system-log">
                                    <span className="nav-text">Registro de erros</span>
                                </NavLink>
                            </Menu.Item>}
                        {this.props.permissions.includes("tokens.list") &&
                            <Menu.Item key="/administrator/tokens">
                                <NavLink to="/administrator/tokens">
                                    <span className="nav-text">Tokens de API</span>
                                </NavLink>
                            </Menu.Item>}
                    </SubMenu>
                )}
                {this.props.permissions.includes("ads.list") && <Menu.Item key="/ads">
                    <NavLink to="/ads">
                        <Icon type="area-chart"/>
                        <span className="nav-text">Anúncio</span>
                    </NavLink>
                </Menu.Item>}
                {this.props.permissions.includes("faq.list") && <Menu.Item key="/faq">
                    <NavLink to="/faq">
                        <Icon type="question-circle"/>
                        <span className="nav-text">Ajuda</span>
                    </NavLink>
                </Menu.Item>}
                {this.props.permissions.includes("activities.list") && <Menu.Item key="/activities">
                    <NavLink to="/activities">
                        <Icon type="file-text"/>
                        <span className="nav-text">Atividades</span>
                    </NavLink>
                </Menu.Item>}
                {this.props.permissions.includes("ratings.list") && <Menu.Item key="/ratings">
                    <NavLink to="/ratings">
                        <Icon type="star"/>
                        <span className="nav-text">Avaliações</span>
                    </NavLink>
                </Menu.Item>}
                {this.props.permissions.includes("comments.list") && <Menu.Item key="/comments">
                    <NavLink to="/comments">
                        <Icon type="message"/>
                        <span className="nav-text">Coméntarios</span>
                    </NavLink>
                </Menu.Item>}
                {this.props.permissions.includes("ask-agronomists.list") && (
                    <SubMenu key="/ask-agronomists"
                             title={<span><Icon type="question-circle"/><span>Consulte os agrônomos</span></span>}>
                        <Menu.Item key="/ask-agronomists/questions">
                            <NavLink to="/ask-agronomists/questions">
                                <span className="nav-text">Perguntas</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="/ask-agronomists/answers">
                            <NavLink to="/ask-agronomists/answers">
                                <span className="nav-text">Respostas</span>
                            </NavLink>
                        </Menu.Item>
                    </SubMenu>
                )}
                {(conteudoSubMenu.some(conteudo => this.props.permissions.includes(conteudo.permission))) && (
                    <SubMenu key="/contents" title={<span><Icon type="file-text"/><span>Conteúdos</span></span>}>
                        {conteudoSubMenu.sort(sortByText).map(conteudo => {
                                if (this.props.permissions.includes(conteudo.permission)) {
                                    return (
                                        <Menu.Item key={conteudo.url}>
                                            <NavLink to={conteudo.url}>
                                                <span className="nav-text">{conteudo.text}</span>
                                            </NavLink>
                                        </Menu.Item>
                                    )
                                }
                            }
                        )}
                    </SubMenu>
                )}
                {(
                    this.props.permissions.includes("settings-social.list")
                    || this.props.permissions.includes("settings-notifications.list")
                    || this.props.permissions.includes("settings-general.list")) && (
                    <SubMenu key="/settings" title={<span><Icon type="setting"/><span>Configuração</span></span>}>
                        {this.props.permissions.includes("settings-general.list") && <Menu.Item key="/settings/general">
                            <NavLink to="/settings/general">
                                <span className="nav-text">Geral</span>
                            </NavLink>
                        </Menu.Item>}
                        {this.props.permissions.includes("settings-notifications.list") &&
                            <Menu.Item key="/settings/notifications">
                                <NavLink to="/settings/notifications">
                                    <span className="nav-text">Notificações</span>
                                </NavLink>
                            </Menu.Item>}
                        {this.props.permissions.includes("settings-social.list") && <Menu.Item key="/settings/social">
                            <NavLink to="/settings/social">
                                <span className="nav-text">Rede social</span>
                            </NavLink>
                        </Menu.Item>}
                    </SubMenu>
                )}
                {this.props.permissions.includes("denunciations.list") && <Menu.Item key="/denunciations">
                    <NavLink to="/denunciations">
                        <Icon type="security-scan"/>
                        <span className="nav-text">Denúncias</span>
                    </NavLink>
                </Menu.Item>}
                {(
                    this.props.permissions.includes("about.list")
                    || this.props.permissions.includes("privacy-policy.list")
                    || this.props.permissions.includes("terms-of-use.list")) && (
                    <SubMenu key="/institutional"
                             title={<span><Icon type="info-circle"/><span>Institucional</span></span>}>
                        {this.props.permissions.includes("privacy-policy.list") &&
                            <Menu.Item key="/institutional/privacy-policy">
                                <NavLink to="/institutional/privacy-policy">
                                    <span className="nav-text">Política de privacidade</span>
                                </NavLink>
                            </Menu.Item>}
                        {this.props.permissions.includes("about.list") && <Menu.Item key="/institutional/about">
                            <NavLink to="/institutional/about">
                                <span className="nav-text">Sobre</span>
                            </NavLink>
                        </Menu.Item>}
                        {this.props.permissions.includes("terms-of-use.list") &&
                            <Menu.Item key="/institutional/terms-of-use">
                                <NavLink to="/institutional/terms-of-use">
                                    <span className="nav-text">Termos de uso</span>
                                </NavLink>
                            </Menu.Item>}
                    </SubMenu>
                )}
                {this.props.permissions.includes("splash.list") && <Menu.Item key="/splash">
                    <NavLink to="/splash">
                        <Icon type="picture"/>
                        <span className="nav-text">Splash</span>
                    </NavLink>
                </Menu.Item>}
                {(
                    this.props.permissions.includes("push-general.list")
                    || this.props.permissions.includes("push-user.list")) && (
                    <SubMenu key="/push" title={<span><Icon type="notification"/><span>Push</span></span>}>
                        {this.props.permissions.includes("push-general.list") && <Menu.Item key="/push/general">
                            <NavLink to="/push/general">
                                <span className="nav-text">Geral</span>
                            </NavLink>
                        </Menu.Item>}
                        {this.props.permissions.includes("push-city.list") && <Menu.Item key="/push/city">
                            <NavLink to="/push/city">
                                <span className="nav-text">Por cidade</span>
                            </NavLink>
                        </Menu.Item>}
                        {this.props.permissions.includes("push-state.list") && <Menu.Item key="/push/state">
                            <NavLink to="/push/state">
                                <span className="nav-text">Por estado</span>
                            </NavLink>
                        </Menu.Item>}
                        {this.props.permissions.includes("push-user.list") && <Menu.Item key="/push/user">
                            <NavLink to="/push/user">
                                <span className="nav-text">Por usuário</span>
                            </NavLink>
                        </Menu.Item>}
                    </SubMenu>
                )}
                {this.props.permissions.includes("report-a-problem.list") && <Menu.Item key="/report-a-problem">
                    <NavLink to="/report-a-problem">
                        <Icon type="bug"/>
                        <span className="nav-text">Problemas relatados</span>
                    </NavLink>
                </Menu.Item>}
                {this.props.permissions.includes("report-a-problem.list") && <Menu.Item key="/report-a-problem">
                    <NavLink to="/suggestions">
                        <Icon type="info-circle" style={{rotate: '180deg'}}/>
                        <span className="nav-text">Sugestões</span>
                    </NavLink>
                </Menu.Item>}
                {this.props.permissions.includes("customers.list") && <Menu.Item key="/customers">
                    <NavLink to="/customers">
                        <Icon type="team"/>
                        <span className="nav-text">Usuários</span>
                    </NavLink>
                </Menu.Item>}
                {this.props.permissions.includes("customers.list") && <Menu.Item key="/customers-deleted">
                    <NavLink to="/customers-deleted">
                        <Icon type="user-delete"/>
                        <span className="nav-text">Usuários excluídos</span>
                    </NavLink>
                </Menu.Item>}
            </Menu>
        )
    }
}

const mapStateToProps = (state) => {
    // TODO: verificar sobre permissão para ver os itens do menu.
    return {
        permissions: state.auth.userData.permissions,
    };
};

export default connect(mapStateToProps)(withRouter(MainNavigation));
